import { Coordinates } from '../../types/map.types';

export const CoordinatesByLanguage: { [key: string]: Coordinates } = {
  'pl': {
    lat: 51.9189,
    lng: 14.1344,
  },
  'de': {
    lat: 50.1657,
    lng: 5.4515,
  },
  'en': {
    lat: 53.5500,
    lng: -7.4333,
  },
  'fr': {
    lat: 46.2276,
    lng: 0.2137,
  },
  'es': {
    lat: 40.4637,
    lng: -5.7492,
  },
  'cz': {
    lat: 50.073658,
    lng: 14.418540,
  },
  'hr': {
    lat: 44.4738,
    lng: 10.4689,
  },
};

export const MobileCoordinatesByLanguage: { [key: string]: Coordinates } = {
  'pl': {
    lat: 51.9189,
    lng: 18.1344,
  },
  'de': {
    lat: 50.1657,
    lng: 10.4515,
  },
  'en': {
    lat: 53.5500,
    lng: -2.4333,
  },
  'fr': {
    lat: 46.2276,
    lng: 2.2137,
  },
  'es': {
    lat: 40.4637,
    lng: -3.7492,
  },
  'cz': {
    lat: 48.073658,
    lng: 14.418540,
  },
  'hr': {
    lat: 42.4738,
    lng: 15.4689,
  },
};

export const RadiusByLanguage: { [key: string]: number } = {
  'pl': 30000,
  'de': 100000,
  'en': 100000,
  'fr': 100000,
  'es': 100000,
  'cz': 30000,
  'hr': 30000,
}
