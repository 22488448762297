import React, {
  FC,
  memo,
  RefObject,
  useEffect,
  useRef,
} from 'react';

import { enableEnterKey } from './Autocomplete.helper';

import SearchIcon from '@assets/svg/search.svg';
import s from './SearchBox.module.scss';

interface ISearchBox {
  maps: any;
  refInput: RefObject<HTMLInputElement>;
  placeholder: string;

  handlePlacesChange(searchBox: any): void;

  resetSearching(): void;
}

const SearchBox: FC<ISearchBox> = ({ maps, refInput, handlePlacesChange, resetSearching, placeholder }) => {
  const autocomplete = useRef<any>(null);

  useEffect(() => {
    if (!autocomplete.current && maps) {
      autocomplete.current = new maps.places.Autocomplete(refInput.current, { types: ['(cities)'] });
      autocomplete.current?.addListener('place_changed', () => handlePlacesChange(autocomplete.current));
    }

    return () => {
      if (maps) {
        autocomplete.current = null;
        maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [maps]);

  useEffect(() => {
    if (refInput.current !== null) enableEnterKey(refInput.current);
  }, [refInput.current]);

  return (
    <div className={s.searchbox}>
      <input
        className={s.searchbox__input}
        ref={refInput}
        placeholder={placeholder}
        type="text"
        onChange={(e) => {
          if (e.target.value === '') resetSearching();
        }}
      />
      <SearchIcon className={s.searchbox__icon} />
    </div>
  );
};

export default memo(SearchBox);
