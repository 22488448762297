import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { RefObject } from 'react';

const MIN_TABLET = 768;

export const scrollToTheTop = () => {
  if (typeof window !== undefined) {
    gsap.to(window, { scrollTo: 0 });
  }
};

export const scrollToMap = () => {
  gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

  if (typeof window !== undefined) {
    if (window.innerWidth < MIN_TABLET)
      gsap.to(window, { scrollTo: '#map' });
  }
};

export const showBackToTheListDependingOnScrollPosition = (backToTheListButton: RefObject<HTMLDivElement>) => {
  gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

  if (backToTheListButton.current !== null) {
    gsap.set(backToTheListButton.current, { autoAlpha: 0 });
    gsap.to(backToTheListButton.current, {
      autoAlpha: 1,
      scrollTrigger: {
        start: '-30% 0',
        end: '-10% 0',
        trigger: '#map',
        scrub: 1,
      },
    });
  }
};
