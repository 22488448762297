import React, { FC, ReactNode, RefObject, useEffect, useRef, useState } from 'react';

import GoogleMapReact, { BootstrapURLKeys, ChangeEventValue } from 'google-map-react';

import { useIntl } from 'gatsby-plugin-intl';
import qs from 'qs';

import { useWindowSize } from '../../hooks/use-window-size';

import { Coordinates, PlaceEdge } from 'types';
import mapStyle from '@helpers/mapStyle.json';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';

import { scrollToMap, scrollToTheTop, showBackToTheListDependingOnScrollPosition } from './Map.animations';

import Marker from './marker/Marker.component';
import SearchBox from './search-box/SearchBox.component';
import Item from './item/Item.component';
import Info from './info/Info.component';

import { CoordinatesByLanguage, MobileCoordinatesByLanguage, RadiusByLanguage } from './Map.helper';

import styleMarker from './marker/Marker.module.scss';
import styleListItem from './item/Item.module.scss';

import s from './Map.module.scss';


const ZOOM_DEFAULT = 6;
const ZOOM_PLACE = 13;

interface IMap {
  places: PlaceEdge[];
  searchBoxTitle: ReactNode;
  backToTheList: string;
  placeholder: string;
  searchParams: string;
}

const Map: FC<IMap> = (props) => {
  const intl = useIntl();
  const {
    places,
    searchBoxTitle,
    backToTheList,
    placeholder,
    searchParams,
  } = props;

  const windowSize = useWindowSize();
  const isMobile = isMobileResolution(windowSize, BREAKPOINT.DESKTOP);
  const COORDS_DEFAULT = isMobile? MobileCoordinatesByLanguage[intl.locale] : CoordinatesByLanguage[intl.locale];

  const [googleMaps, setGoogleMaps] = useState<any>(null);
  const [mapZoom, setMapZoom] = useState<number>(ZOOM_DEFAULT);
  const [mapCenter, setMapCenter] = useState<Coordinates>(COORDS_DEFAULT);
  const [isInfoVisible, setIsInfoVisible] = useState<boolean>(false);
  const [clickedIndex, setClickedIndex] = useState<number>(-1);
  const [isMapAbleToUpdatePosition, setIsMapAbleToUpdatePosition] = useState<boolean>(false);
  const [sortedLocation, setSortedLocation] = useState(places)
  const [sortedLocationReference, setSortedLocationReference] = useState<any>(useRef<RefObject<HTMLLIElement>[]>(sortedLocation.map(() => React.createRef<HTMLLIElement>())))
  const [sortedLocationMarkersReference, setSortedLocationMarkersReference] = useState<any>(useRef<RefObject<HTMLDivElement>[]>(sortedLocation.map(() => React.createRef<HTMLDivElement>())))
  
  const refSearchboxInput = useRef<HTMLInputElement>(null);
  const refBackToTheListButton = useRef<HTMLDivElement>(null);

  const bootstrapUrlKeys: BootstrapURLKeys = {
    key: process.env.GATSBY_GOOGLE_MAPS_API_KEY ? process.env.GATSBY_GOOGLE_MAPS_API_KEY : '',
    libraries: ['places', 'geometry'],
    region: intl.locale,
    language: intl.locale,
  };

  const handleApiLoaded = (callbackObject: { map: any, maps: any }): void => {
    const { map, maps } = callbackObject;
    if (map && maps) {
      setGoogleMaps(maps);
    }
  };

  const handlePlacesChange = (searchBox: any): void => {
    const searchedPlace = searchBox.getPlace();

    if (Object.keys(searchedPlace).length !== 1) {
      goToSearchedLocation(searchedPlace);

      if (googleMaps) {
        const fromPlace = new googleMaps.LatLng(searchedPlace.geometry.location.lat(), searchedPlace.geometry.location.lng());
        calculateDistanceAndHideAppropriateLocations(fromPlace);
      }

      scrollToMap();
      replaceWindowLocation(searchedPlace.name, searchedPlace.geometry.location.lat(), searchedPlace.geometry.location.lng());
    }
    else {
      setSortedLocation(places)
    }
  };

  const replaceWindowLocation = (cityName: string, lat: string, lng: string) => {
    if (typeof window !== `undefined`) {
      window.history.replaceState({}, '', `?w=${lat}&h=${lng}&location=${cityName}`);
    }
  };

  const resetSearching = () => {
    for (let i = 0; i < sortedLocation.length; i++) {
      const listItem = sortedLocationMarkersReference.current[i] && sortedLocationReference.current[i].current;
      const marker = sortedLocationMarkersReference.current[i] && sortedLocationMarkersReference.current[i].current;
      listItem?.classList.remove(styleListItem.hidden);
      marker?.classList.remove(styleMarker.hidden);
    }
    setMapZoom(ZOOM_DEFAULT);
    setMapCenter(COORDS_DEFAULT);
    closeInfo();
    setSortedLocation(places);
  };

  const calculateDistanceAndHideAppropriateLocations = (fromPlace: unknown) => {
    if (googleMaps) {
      
      const visibleLocation: {location: PlaceEdge, dist: number}[] = []
      const visibleLocationReference: RefObject<RefObject<HTMLLIElement>>[]= []
      const visibleLocationMarkerReference: RefObject<RefObject<HTMLDivElement>>[] = []

      sortedLocation.map((place, index) => {
        const lat = place.node.placeData.locLocation.latitude;
        const lng = place.node.placeData.locLocation.longitude;

        const toPlace = new googleMaps.LatLng(lat, lng);
        const distance = googleMaps.geometry.spherical.computeDistanceBetween(fromPlace, toPlace);

        const listItem = sortedLocationReference.current[index] && sortedLocationReference.current[index].current;
        const marker = sortedLocationMarkersReference.current[index] && sortedLocationMarkersReference.current[index].current;

        if (distance < RadiusByLanguage[intl.locale]) {
          visibleLocation.push({location: place, dist: distance})
          visibleLocationReference.push({ current: sortedLocationReference.current[index] })
          visibleLocationMarkerReference.push({ current: sortedLocationMarkersReference.current[index] })
          listItem?.classList.remove(styleListItem.hidden);
          marker?.classList.remove(styleMarker.hidden);
        } 
      });

     const sortedLocationByDistance = visibleLocation && visibleLocation.sort((locationA, locationB) => {
        return locationA.dist - locationB.dist
      })
      
      const sortedPlaces = sortedLocationByDistance.map((locations) => {
        return locations.location;
      })

      setSortedLocationReference({current: visibleLocationReference})
      setSortedLocationMarkersReference({current: sortedLocationMarkersReference})

      setSortedLocation(sortedPlaces);
    }
  };

  const goToSearchedLocation = (searchedPlace: any) => {
    const coords: Coordinates = {
      lat: searchedPlace.geometry.location.lat(),
      lng: searchedPlace.geometry.location.lng(),
    };
    setMapCenter(coords);
    setMapZoom(ZOOM_PLACE);
    closeInfo();
  };

  const closeInfo = () => {
    setIsInfoVisible(false);
    setClickedIndex(-1);
  };

  const goToStoreLocation = (index: number): void => {
    const store = sortedLocation[index].node;
    const lat = store.placeData.locLocation.latitude;
    const lng = store.placeData.locLocation.longitude;

    if (lat === null || lng === null) return;

    const coords: Coordinates = { lat, lng };
    setMapCenter(coords);
    setMapZoom(ZOOM_PLACE);
    setClickedIndex(index);
    setIsInfoVisible(true);
    setIsMapAbleToUpdatePosition(true);
  };

  const setMapCenterAndZoom = ({ zoom, center }: ChangeEventValue): void => {
    if (isMapAbleToUpdatePosition) {
      setMapCenter(center);
      setMapZoom(zoom);
    }
  };

  const createMapOptions = () => {
    return {
      styles: mapStyle,
      zoomControl: false,
    };
  };

  useEffect(() => {
    if (searchParams !== '') {
      const search = qs.parse(window.location.search.substr(1));
      if (search.hasOwnProperty('w') && search.hasOwnProperty('h') && search.hasOwnProperty('location')) {
        if (refSearchboxInput.current !== null) {
          refSearchboxInput.current.value = search.location as string;
        }
        const lat = parseFloat(search.w as string);
        const lng = parseFloat(search.h as string);

        if (googleMaps) {
          const fromPlace = new googleMaps.LatLng(lat, lng);
          calculateDistanceAndHideAppropriateLocations(fromPlace);
        }

        setMapCenter({ lat, lng });
        setMapZoom(ZOOM_PLACE);
        closeInfo();
      }
    }
  }, [searchParams, googleMaps]);

  useEffect(() => {
    showBackToTheListDependingOnScrollPosition(refBackToTheListButton);
  }, [refBackToTheListButton]);

  useEffect(() => {
    setMapCenter(isMobile? MobileCoordinatesByLanguage[intl.locale] : CoordinatesByLanguage[intl.locale]);
  },[intl.locale]);

  return (
    <div className={s.map}>
      <div className={s.map__searchBox}>
        <div className={s.map__segment}>
          <h1 className={s.map__title}>{searchBoxTitle}</h1>
          <div className={s.map__search}>
            <SearchBox
              maps={googleMaps}
              placeholder={placeholder}
              refInput={refSearchboxInput}
              handlePlacesChange={handlePlacesChange}
              resetSearching={resetSearching}
            />
          </div>
        </div>
        <div className={s.map__list}>
          <ul>
            {
              sortedLocation.map((place: PlaceEdge, index: number) => {
                return <Item
                  refListItem={sortedLocationReference.current[index]}
                  index={index}
                  refMarkers={sortedLocationMarkersReference}
                  goToLocation={goToStoreLocation}
                  key={index}
                  place={place}
                  navigateButtonContent={intl.formatMessage({ id: 'stores__buttonNavigate' })}
                  callButtonContent={intl.formatMessage({ id: 'stores__buttonCall' })}
                />;
              })
            }
          </ul>
        </div>
      </div>
      <div className={s.map__container} id={'map'}>
        <div className={s.map__back} onClick={scrollToTheTop} ref={refBackToTheListButton}>{backToTheList}</div>
        <GoogleMapReact
          bootstrapURLKeys={bootstrapUrlKeys}
          center={mapCenter}
          zoom={mapZoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={(callbackObject) => handleApiLoaded(callbackObject)}
          onChange={setMapCenterAndZoom}
          options={createMapOptions}
        >
          {
            sortedLocation.map((place: PlaceEdge, index: number) => {
              const store = place.node;

              if (store.placeData.locName === null || store.placeData.locLocation.latitude === null || store.placeData.locLocation.longitude === null) return;

              return (
                <Marker
                  lat={store.placeData.locLocation.latitude}
                  lng={store.placeData.locLocation.longitude}
                  key={index}
                  refMarker={sortedLocationMarkersReference.current[index]}
                  handleMarkerClick={() => goToStoreLocation(index)}
                  place={place}
                  ownedIndex={index}
                  clickedIndex={clickedIndex}
                  closeInfo={closeInfo}
                />
              );
            })
          }
        </GoogleMapReact>
        {
          isInfoVisible
            ? <Info
              place={sortedLocation[clickedIndex]}
              navigateButtonContent={intl.formatMessage({ id: 'stores__buttonNavigate' })}
              callButtonContent={intl.formatMessage({ id: 'stores__buttonCall' })}
            />
            : ''}
      </div>
    </div>
  );
};

export default Map;
