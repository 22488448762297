import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { useIntl } from 'gatsby-plugin-intl';

import { animateCorrespondingMarker } from '../marker/Marker.animations';
import { scrollToMap } from '../Map.animations';
import { PlaceEdge } from 'types';
import { getDirectionsUrl } from '@helpers/google.helper';

import Arrow from '@assets/svg/arrow.svg';
import Facebook from '@assets/svg/facebook.svg';
import Instagram from '@assets/svg/instagram.svg';
import Linkedin from '@assets/svg/linkedin.svg';

import s from './Item.module.scss';

interface IItem {
  refListItem: RefObject<HTMLLIElement>;
  index: number;
  refMarkers: RefObject<RefObject<HTMLDivElement>[]>;
  place: PlaceEdge;
  navigateButtonContent: string;
  callButtonContent: string;

  goToLocation(index: number): void;
}

const Item: FC<IItem> = (props) => {
  const {
    refListItem,
    index,
    goToLocation,
    refMarkers,
    place: {
      node: {
        placeData: {
          locAddress,
          locName,
          locPhone,
          locUrlfacebook,
          locUrlinstagram,
          locUrllinkedin,
          locUrlstore,
          locLocation: {
            latitude,
            longitude,
          },
        },
      },
    },
    navigateButtonContent,
    callButtonContent,
  } = props;
  const intl = useIntl();
  
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const refArrow = useRef<HTMLDivElement>(null);
  const refMoreInfo = useRef<HTMLDivElement>(null);

  const directionsUrl = getDirectionsUrl(latitude, longitude);
  let timeline = gsap.timeline();

  const toggleIsExpanded = (): void => {
    setIsExpanded(state => !state);
  };

  const expandItem = () => {
    timeline
      .to(refListItem.current, {
        height: 'auto',
        duration: .3,
      })
      .to(refMoreInfo.current, {
        autoAlpha: 1,
        duration: .3,
      }, '<0.1')
      .to(refArrow.current, {
        rotate: '180deg',
        duration: .3,
      }, '+=0');
  };

  const collapseItem = () => {
    timeline
      .to(refListItem.current, {
        height: '110px',
        duration: .4,
      })
      .to(refMoreInfo.current, {
        autoAlpha: 0,
        duration: .1,
      }, '<')
      .to(refArrow.current, {
        rotate: '0deg',
        duration: .4,
      });
  };

  const goToLocationAndScroll = () => {
    scrollToMap();
    goToLocation(index);
  };

  useEffect(() => {
    timeline = gsap.timeline();
    if (refListItem && refListItem.current !== null && refArrow.current !== null) {
      isExpanded ? expandItem() : collapseItem();
    }

    return () => {
      timeline.kill();
    };
  }, [isExpanded]);

  return (
    <li
      className={`${s.item} ${ isExpanded? s.expanded : '' }`}
      key={index}
      ref={refListItem}
      onMouseEnter={() => animateCorrespondingMarker(index, refMarkers)}
    >
      <div className={s.item__content} onClick={goToLocationAndScroll}>
        <div className={s.item__row}>
          <div className={`${s.item__name} ${s.item__text}`}>{locName}</div>
          {locPhone ? <p className={s.item__text}>tel. {locPhone}</p> : ''}
        </div>
        <div className={s.item__row}>
          <p className={s.item__text}>
            {locAddress}
          </p>
        </div>
      </div>
      <div ref={refMoreInfo} className={s.item__more}>
        <div className={s.item__buttons}>
          <a href={directionsUrl} hrefLang={intl.locale} className={s.item__button} target={'_blank'}
             rel="noopener noreferrer" aria-label={navigateButtonContent}>{navigateButtonContent}</a>
          {
            locUrlstore
              ? <a 
                href={locUrlstore} 
                hrefLang={intl.locale}
                className={s.item__button}
                target={'_blank'} 
                rel="noopener noreferrer"
                aria-label={'www'}
              >www</a>
              : ''
          }
          {
            locPhone
              ? <a 
                href={`tel:${locPhone}`}
                hrefLang={intl.locale} 
                className={s.item__button}
                rel="noopener noreferrer"
                aria-label={callButtonContent}
              >{callButtonContent}</a>
              : ''
          }
        </div>
        <div className={s.item__buttons}>
          {
            locUrlfacebook
              ? <a 
                href={locUrlfacebook}
                hrefLang={intl.locale}
                className={s.item__icon} 
                target={'_blank'} 
                rel="noopener noreferrer"
                aria-label={'Facebook address'}
              >
                <Facebook className={s.item__iconSvg} />
              </a>
              : ''
          }
          {
            locUrlinstagram
              ? <a 
                href={locUrlinstagram} 
                hrefLang={intl.locale} 
                className={s.item__icon} 
                target={'_blank'}
                rel="noopener noreferrer"
                aria-label={'Instagram address'}
              >
                <Instagram className={s.item__iconSvg} />
              </a>
              : ''
          }
          {
            locUrllinkedin
              ? <a href={locUrllinkedin} 
                   hrefLang={intl.locale} 
                   className={s.item__icon} 
                   target={'_blank'} 
                   rel="noopener noreferrer"
                   aria-label={'LinkedIn address'}
              >
                <Linkedin className={s.item__iconSvg} />
              </a>
              : ''
          }
        </div>
      </div>
      <div className={s.item__arrow} onClick={toggleIsExpanded} ref={refArrow}>
        <Arrow className={s.item__arrowSvg} />
      </div>
    </li>
  );
};

export default Item;
