import { RefObject } from 'react';
import { gsap } from 'gsap';

export const animateCorrespondingMarker = (index: number, refMarkers: RefObject<RefObject<HTMLDivElement>[]>): void => {
  if (refMarkers && refMarkers.current) {

    const marker: HTMLDivElement | null = refMarkers.current[index] && refMarkers.current[index].current;

    const tl = gsap.timeline();

    tl
      .to(marker, {
        yPercent: '-80',
      })
      .to(marker, {
        yPercent: '0',
        ease: 'Bounce.easeOut',
        duration: 1,
      });
  }
};
