import React, { FC, RefObject, memo } from 'react';

import { useIntl } from 'gatsby-plugin-intl';

import { PlaceEdge } from 'types';

import Info from '../info/Info.component';

import Pin from '@assets/svg/pin.svg';

import s from './Marker.module.scss';

interface IMarker {
  lat: number;
  lng: number;
  refMarker: RefObject<HTMLDivElement>;
  place: PlaceEdge;
  clickedIndex: number
  ownedIndex: number
  closeInfo: () => void;

  isVisible?: boolean;
  handleMarkerClick?: () => void;
}

const Marker: FC<IMarker> = (props) => {
  const {
    refMarker,
    handleMarkerClick,
    place,
    clickedIndex,
    ownedIndex,
    closeInfo,
    isVisible = true,
  } = props;

  const intl = useIntl();

  const shouldInfoBeDisplayed = clickedIndex === ownedIndex;

  return (
    <div
      className={`${s.marker} ${isVisible ? '' : s.hidden }`}
      ref={refMarker}
      style={clickedIndex === ownedIndex ? { zIndex: 100 } : {}}
    >
      {
        shouldInfoBeDisplayed &&
        <Info
          isDesktop
          place={place}
          navigateButtonContent={intl.formatMessage({ id: 'stores__buttonNavigate' })}
          callButtonContent={intl.formatMessage({ id: 'stores__buttonCall' })}
          closeTooltip={closeInfo}
        />

      }

      <Pin onClick={handleMarkerClick} />
    </div>
  );
};

export default memo(Marker);
